const locales = [
  {
    locale: 'en',
    messages: import('./en'),
    //loadData: import(`@formatjs/intl-relativetimeformat/dist/locale-data/en`),
  },
  {
    locale: 'hr',
    messages: import('./hr'),
    //loadData: import(`@formatjs/intl-relativetimeformat/dist/locale-data/hr`),
  }
]

export default locales
