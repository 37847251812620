import React, { Component } from 'react'
import App from 'base-shell/lib'
import config from './config'

// Swiper css
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/swiper.min.css";

export default class Demo extends Component {
  render() {
    return <App config={config} />
  }
}
