/* eslint-disable react/jsx-key */
import React, {lazy} from 'react'
import {Route} from 'react-router-dom'

const Impressum = lazy(() => import('../pages/Impressum/Impressum'))
const Privacypolicy = lazy(() => import('../pages/Privacypolicy/Privacypolicy'))
const TermsAndConditions = lazy(() => import('../pages/TermsAndConditions/TermsAndConditions'))
const SeminarContent = lazy(() => import('../pages/SeminarContent/SeminarContent'))

const routes = [
    <Route path="/impressum" exact component={Impressum}/>,
    <Route path="/privacy_policy" exact component={Privacypolicy}/>,
    <Route path="/terms_and_conditions" exact component={TermsAndConditions}/>,
    <Route path="/seminar_content" exact component={SeminarContent}/>,
]

export default routes
